@keyframes row-animate {
  0% {
    background-color: rgba(0, 26, 255, 0);
  }
  7% {
    background-color: rgba(16, 16, 17, 0.176);
  }
  17% {
    background-color: rgba(16, 16, 17, 0.024);
  }
  70% {
    background-color: rgba(16, 16, 17, 0.024);
  }
  100% {
    background-color: rgba(16, 16, 17, 0.04);
  }
}
