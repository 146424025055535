@import '@/styles/variables.scss';
















































































































































































@import '~@core/preset/preset/mixins.scss';

@include theme(app-bar-autocomplete-box) using ($material) {
  div[role='combobox'] {
    background-color: map-deep-get($material, 'cards');
  }
}

.app-bar-autocomplete-box {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 14px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;

  .v-input__control {
    height: 100%;
  }

  div[role='combobox'] {
    height: 100%;
    z-index: 10;
    padding: 0 20px !important;

    .v-input__prepend-inner,
    .v-input__append-inner {
      align-self: center;
      margin-top: -2px;
      svg {
        font-size: 24px;
        height: 24px;
        width: 24px;
      }
    }

    .v-input__prepend-inner {
      @include ltr() {
        margin-left: 4px;
      }
      @include rtl() {
        margin-right: 4px;
      }
    }
  }
  // Content - Result
  .v-menu__content {
    @include style-scroll-bar();
    max-width: unset;
    width: 100%;
    border-radius: 12px !important;
    top: calc(64px + 0.625rem) !important;

    .v-subheader {
      text-transform: uppercase;
      letter-spacing: 0.21px;
      font-size: 0.75rem;
      font-weight: 500;
    }

    @include theme(v-subheader) using ($material) {
      color: map-deep-get($material, 'text', 'disabled');
    }

    .v-list-item {
      > [id^='list-item'] {
        width: 100%;
      }
    }
  }

  @at-root .v-app-bar.app-bar-shinked & {
    div[role='combobox'] {
      padding: 0 !important;
    }

    // Content - Result
    .v-menu__content {
      max-width: unset;
      width: calc(100% + 20px * 2);
      @include ltr() {
        margin-left: -20px;
      }
      @include rtl() {
        margin-right: -20px;
      }
    }
  }
}

// ————————————————————————————————————
//* ——— Horizontal Nav
// ————————————————————————————————————

.content-layout.horizontal-nav {
  .app-system-bar {
    // Assigning 7 z-index so that search result can be seen on top of navigation menu
    z-index: 7;

    .v-text-field {
      margin-top: 0;
      padding-top: 0;
    }

    // Remove 3rem padding from both side.
    // ? 3rem is applied to navbar-content-container
    .app-bar-autocomplete-box {
      max-width: calc(100% - 3rem * 2);
      @include ltr() {
        margin-left: 3rem;
      }
      @include rtl() {
        margin-right: 3rem;
      }
    }

    // ? In Full content contet have padding of 1.5rem
    &:not(.app-system-bar-boxed) {
      .app-bar-autocomplete-box {
        max-width: calc(100% - 1.5rem * 2);
        @include ltr() {
          margin-left: 1.5rem;
        }
        @include rtl() {
          margin-right: 1.5rem;
        }
      }
    }
  }
}
