.v-overlay {
  &--active {
    background: linear-gradient(
        0deg,
        rgba(80, 90, 104, 0.5),
        rgba(80, 90, 104, 0.5)
      ),
      rgba(255, 255, 255, 0.3) !important;
    backdrop-filter: blur(12px);
  }
}

.modal {
  padding: 40px 80px;
  &--centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &-status {
    text-align: center;
    margin-bottom: 34px;
    &__circle {
      width: 84px;
      height: 84px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--success {
      background-color: #06fccd;
      color: #fff;
      svg {
        width: 49px;
        height: 37px;
      }
    }
    &--warning {
      background-color: #f9b60d;
      color: #fff;
      svg {
        width: 49px;
        height: 37px;
      }
    }
    &--error {
      background-color: #ff4c51;
      color: #fff;
      svg {
        width: 49px;
        height: 37px;
      }
    }
  }
}
