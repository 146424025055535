@import '@/styles/variables.scss';
/* Theme */
@import "@core/preset/preset/pages/auth.scss";
@import "@core/preset/preset/misc.scss";

/* Typography */
@import "fonts";

/* Variables */
@import "variables";
@import 'mixins';
/* Normalize */
// @import "normalize";

/* Components */
@import "common/card";
@import "common/input";
@import "common/btn";
@import "common/footer";
@import "common/modal";
@import "common/animation";
@import "common/tables";
/* Pages */
@import "pages/login";
.v-application .theme--light.v-sheet,
.theme--light.v-sheet {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #2830ce !important;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 24px;
    }
  }
}
.v-card--transparent {
box-shadow: none!important;

}
.v-btn {
text-transform: none!important;}
.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("~@/assets/images/backgrounds/bg_top_left.svg") no-repeat top
      64px left,
    url("~@/assets/images/backgrounds/bg_bottom_left.svg") no-repeat bottom left,
    url("~@/assets/images/backgrounds/bg_bottom_right.svg") no-repeat bottom
      right,
    url("~@/assets/images/backgrounds/bg_top_right.svg") no-repeat top 64px
      right !important;

  background-color: transparent;
  &--no-offset {
    background: url("~@/assets/images/backgrounds/bg_top_left.svg") no-repeat top
    0px left,
    url("~@/assets/images/backgrounds/bg_bottom_left.svg") no-repeat bottom left,
    url("~@/assets/images/backgrounds/bg_bottom_right.svg") no-repeat bottom
    right,
    url("~@/assets/images/backgrounds/bg_top_right.svg") no-repeat top 0px
    right !important;
  }
}
.v-application--wrap,
.v-application {
  background: transparent !important;
}
.homepage-illustration {
  align-self: center;
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .homepage-illustration {
  }
}
.dir {
  &-column {
    flex-direction: column;
    display: flex !important;
  }
}

.align-items {
  display: flex;
  &-center {
    align-items: center;
  }
  &-start {
    align-items: flex-start;
  }
  &-end {
    align-items: flex-end;
  }
}

.navigation-menu {
  .v-btn__content {
    span {
      display: none;
    }
  }
}

.horizontal-nav-menu {
  .theme--light.v-btn--active::before {
    opacity: 0.12;
  }
}

.v-image--company-logo {
  .v-image__image {
    background-size: auto 44px;
    background-position: center right !important;
  }
}

p a {
  border-bottom: 1px solid;
  text-decoration: none;
}

.vertical-nav-header {
  h2 {
    display: none;
  }
  .v-image {
    max-width: 180px !important;
  }
}

p {
  white-space: pre-line;
}

.border {
  &-bottom {
    border-bottom: 1px solid rgba(80, 90, 104, 0.22);
  }
  &-top {
    border-top: 1px solid rgba(80, 90, 104, 0.22);
  }
  &-right {
    border-right: 1px solid rgba(80, 90, 104, 0.22);
  }
  &-left {
    border-left: 1px solid rgba(80, 90, 104, 0.22);
  }

  &--dark {
    border-color: #898989 !important;
  }

  &--light {
    border: 1px solid rgba(80, 90, 104, 0.22);
  }
}

.v-card {
  &--no-border {
    border-color: transparent !important;
    border: none !important;
  }
  &--no-bg {
    background-color: transparent !important;
  }
}

.card-shadow {
  display: block;
  max-width: 100%;
  outline: none;
  text-decoration: none;
  transition-property: box-shadow, opacity;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #F3F3F3;
  border-radius: 3px;
}
