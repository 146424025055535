@font-face {
  font-family: "Neue Haas Grotesk Text Pro";
  src: url("~@/assets/fonts/NHaasGroteskTXPro-55Rg.woff2") format("woff2"),
    url("~@/assets/fonts/NHaasGroteskTXPro-55Rg.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Neue Haas Grotesk Text Pro";
  src: url("~@/assets/fonts/NHaasGroteskTXPro-65Md.woff2") format("woff2"),
    url("~@/assets/fonts/NHaasGroteskTXPro-65Md.woff") format("woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Neue Haas Grotesk Text Pro";
  src: url("~@/assets/fonts/NHaasGroteskTXPro-75Bd.woff2") format("woff2"),
    url("~@/assets/fonts/NHaasGroteskTXPro-75Bd.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}
// Added for SVGs
@font-face {
  font-family: "NHaasGroteskTXPro-55Rg";
  src: url("~@/assets/fonts/NHaasGroteskTXPro-55Rg.woff2") format("woff2"),
    url("~@/assets/fonts/NHaasGroteskTXPro-55Rg.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "NHaasGroteskTXPro-65Md";
  src: url("~@/assets/fonts/NHaasGroteskTXPro-65Md.woff2") format("woff2"),
    url("~@/assets/fonts/NHaasGroteskTXPro-65Md.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "NHaasGroteskTXPro-75Bd";
  src: url("~@/assets/fonts/NHaasGroteskTXPro-75Bd.woff2") format("woff2"),
    url("~@/assets/fonts/NHaasGroteskTXPro-75Bd.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}
$font-family: 'Helvetica', sans-serif;

.text {
  font-family: $font-family;
  &-16 {
    font-size: 16px;
  }
  &-12 {
    font-size: 12px;
  }
  &--bold {
    font-weight: bold;
  }
  &--blue {
    color: $text-blue;
    word-break: break-word;
  }
  &--grey {
    color: $text-grey;
    word-break: break-word;
  }
  &--black {
    color: $text-black;
    word-break: break-word;
  }
  &--disabled {
    cursor: not-allowed;
  }
}
