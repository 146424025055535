@import "~@core/preset/preset/variables.scss";

@import "~@core/preset/preset/mixins.scss";

.v-input.required {
  label {
    padding-right: 15px;
    &:after {
      content: "*";
      font-family: $body-font-family;
      position: absolute;
      top: -2px;
      right: 6px;
      font-size: 16px;
    }
  }
}
.auth-wrapper {
  display: flex;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  flex-basis: 100%;
  align-items: center;

  // common style for both v1 and v2
  a {
    text-decoration: unset;
  }
  .auth-card__welcome-text {
    max-width: 325px;
    margin-left: auto;
    margin-right: auto;
    + p {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .auth-card {
    border: 0 !important;
  }
  .support-link {
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  // auth v1
  &.auth-v1 {
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 1.5rem;

    .auth-mask-bg {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    .auth-tree,
    .auth-tree-3 {
      position: absolute;
    }
    .auth-tree {
      bottom: 0;
      left: 0;
    }
    .auth-tree-3 {
      bottom: 0;
      right: 0;
    }

    // auth card
    .auth-inner {
      width: 28rem;
      z-index: 1;
      .auth-card {
        padding: 0.9375rem 0.875rem;
        border: 0 !important;
      }
    }
  }

  //auth v2
  &.auth-v2 {
    align-items: flex-start;

    .auth-inner {
      height: calc(var(--vh, 1vh) * 100);
      width: 100%;
      margin: 0;

      .brand-logo {
        text-decoration: unset;
        position: absolute;
        top: 1.83rem;
        left: 2.5rem;
        z-index: 1;
      }

      .auth-row {
        height: 100%;
        .auth-illustrator-wrapper {
          width: 100%;
          height: 100%;
        }
      }

      .auth-bg-v2 {
        background-image: url("~@/assets/images/login/bg_top_right.svg"),
          url("~@/assets/images/login/bg_bottom_right.svg");
        background-position: top right, bottom right;
        background-repeat: no-repeat;

        background-size: auto;
        .v-card {
          max-width: 415px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
.auth-illustrator-wrapper {
  background-image: url("~@/assets/images/login/bg_top_left.svg"),
    url("~@/assets/images/login/bg_top_left_text.svg"),
    url("~@/assets/images/login/bg_bottom_left.svg"),
    url("~@/assets/images/login/bg_bottom_right_text.svg");
  background-position: top left, top left, bottom left, bottom right;
  background-repeat: no-repeat;
  background-color: #2830ce;
  background-size: auto;
}
@include theme--child(auth-wrapper) using ($material) {
  &.auth-v2 {
    .auth-inner {
      .auth-bg {
        background: map-deep-get($material, "cards");
      }
    }
  }
}

@media (max-width: 600px) {
  // auth bg and tree hide in sm screen
  .auth-v1 {
    .auth-tree,
    .auth-tree-3,
    .auth-mask-bg {
      display: none;
    }
  }
}
