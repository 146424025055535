footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 22px;
  font-size: 14px;
  margin: auto;

  a {
    margin-left: 16px;
    border: none;
    text-decoration: none;
  }
}

.footer {
  align-items: flex-start;
}