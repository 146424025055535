@import '@/styles/variables.scss';





























































































































































































































.user-profile-menu-content {
  hr {
    transform: none !important;
  }
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
