@import '@/styles/variables.scss';





































































































































.v-expansion-panel {
  position: relative;
}
.v-switch--custom {
  position: absolute;
  right: 40px;
  top: -7px;
  z-index: 10;
}
.shadow {
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.v-bottom-sheet.v-dialog--animated {
  transition: none !important;
  animation: none !important;
}
.v-application.skin-variant--bordered .theme--dark.v-card--cc:not(.v-card .v-card) {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
}
.v-dialog.v-bottom-sheet {
  box-shadow: none !important;
}
