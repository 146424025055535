@import '@/styles/variables.scss';

























































@import '~vuetify/src/styles/styles.sass';

.horizontal-nav-menu {
  position: relative;
  // ? As v-menu is sibling of button activator we also have to exclude it
  > *:not(:last-child):not(.v-menu) {
    @include ltr() {
      margin-right: 8px;
    }
    @include rtl() {
      margin-left: 8px;
    }
  }
  > .v-btn {
    .v-icon {
      transition: none !important;
    }
  }

  // Fixes: Items drop on second row
  @media screen and (max-width: 1400px) {
    .v-application .v-app-bar.v-toolbar.navigation-menu .v-toolbar__content & {
      // padding: 0 !important;
      // display: flex;
      // justify-content: center;

      > .v-btn {
        @include ltr() {
          margin-right: 0 !important;
        }
        @include rtl() {
          margin-left: 0 !important;
        }
      }
    }
  }
}

.v-application.horizontal-nav {
  .v-system-bar {
    .app-bar-autocomplete-box {
      left: 3rem !important;
    }
  }
}
