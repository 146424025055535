@import '@/styles/variables.scss';

























































@import '~vuetify/src/styles/styles.sass';

.v-application--is-ltr .v-list.v-sheet--shaped .v-list-item:before {
}

.bg-secondary {
  background: rgba(40, 48, 206, 0.25);
  &::before {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}
.vertical-nav-menu-link {
  color: #2830ce !important;
  &:before {
  }
  &.v-list-item--active {
    box-shadow: none;
    @include elevationTransition();
  }
}
