.auth {
  a {
    text-decoration: underline;
  }
  &-wrapper {
    //max-height: calc(100vh - 45px);
    //min-height: calc(100vh - 45px);
  }
  &-inner {
    //min-height: calc(100vh - 45px);
    //max-height: calc(100vh - 45px);
  }
  &-language-selection {
  display: flex;
    width: 100%;
    right: 0;
    top: 0px;
    justify-content: flex-end;
    max-width: 1440px;
    left: 0;
    position: absolute;
    margin: auto;
    padding: .8rem 6.2rem !important;
  }
  &-resend-code {
    display: block;
    width: 100%;
    text-align: left;
    text-decoration: underline !important;
    font-size: 12px;
  }
  &-nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 12px;
    font-weight: 500;
    a {
      text-decoration: underline;
    }
  }
  &-border-link {
    padding-right: 7px;
    margin-right: 7px;
    border-right: $text-blue 1px solid;
  }
  &-support {
    text-decoration: none;
    font-size: 12px;
  }
}

.vue-tel-input-vuetify .country-code {
  width: 45px !important;
  margin-right: 8px;
}

.vue-tel-input-vuetify .v-input--has-state {
  transform: translateY(-4px);
}
