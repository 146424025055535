@import '@/styles/variables.scss';










































































































































































































































.navbar {
  &-contract-status {
    position: fixed;
    max-width: 100vw !important;
    width: 100vw;
    height: 35px;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    background: #f9b60d;
    z-index: 50;
  }
}
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.closed-status {
  background-color: #37b59d;
}
.declined-status {
  background-color: #f98506;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}

.v-btn--continue-mobile {
  position: fixed;
  right: 0;
  top: 54px;
  border-radius: 0 !important;
  height: 38px !important;
  width: 100% !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  text-transform: none !important;
  color: #06fccd !important;

  span > span {
    display: block !important;
  }
}
