@import '@/styles/variables.scss';




































































.toast {
  max-width: calc(100vw - 365px);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.v-snack__wrapper {
  min-width: calc(100vw - 336px) !important;
  max-width: 1440px !important;
  border-radius: 3px !important;
}

@media only screen and (max-width: 959px) {
  .toast {
    max-width: calc(100vw - 32px);
  }
  .v-snack__wrapper {
    z-index: 9000;
    transform: translateY(-15px);
    min-width: calc(100vw - 32px) !important;
    max-width: calc(100vw - 32px) !important;
    border-radius: 0 !important;
  }
}
