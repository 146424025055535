@import '@/styles/variables.scss';


























































































































































header.v-toolbar--fixed {
  border-radius: 0 !important;
  z-index: 9000 !important;
}
.v-card__title {
  word-break: break-word;
}
.v-dialog__content--active .v-toolbar--fixed {
  position: fixed;
  margin-left: 1px;
  margin-right: 1px;
  left: 0;
  right: 0;
  z-index: 1;
  + div {
    padding-top: 64px !important;
  }
}

.v-avatar--dialog {
  span {
    border-radius: 0 !important;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-toolbar__title {
    font-size: 16px !important;
  }
}
