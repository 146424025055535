@import '@/styles/variables.scss';









































































































































@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins.scss';

@include theme(horizontal-nav-menu) using ($material) {
  // ! Below style will also affect HorizontalNavGroup
  // ? If you only want this style of this component than use selector `> .menu-open` instead of `.menu-open`
  .menu-open {
    background: rgba(map-deep-get($shades, 'black'), map-deep-get($material, 'states', 'hover'));
  }
}

.v-menu__content {
  margin-top: -3px;
}

.horizontal-nav-menu {
  > .group-activator-active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
  .v-list-item__icon {
    align-self: center;
  }
  .v-list-item {
    min-height: 42px;
    max-height: 42px;
  }
}

.horizontal-nav-header-group {
  > .v-menu__content {
    padding-top: 13px;
  }

  .v-menu__content {
    // min-width: 260px;
    width: 260px;
    contain: none !important;
    overflow: visible !important;

    > .v-list {
      border-radius: 6px;
      // @include elevation(8);
    }
  }
}

@include app-elevation('horizontal-nav-header-group .v-menu__content > .v-list', 8);
