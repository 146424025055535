@import '@/styles/variables.scss';
























@import '~@core/layouts/styles/_variables';

.app-content-container {
  &.app-content-container-boxed {
    max-width: $content-max-width;
  }
}
