@import '@/styles/variables.scss';













































































































































































































































































.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar {
  &-contract-status {
    position: fixed;
    max-width: 100vw !important;
    width: 100vw;
    height: 42px;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    background: #f9b60d;
    z-index: 50;
  }
}
.closed-status {
  background-color: #37b59d;
}

.declined-status {
  background-color: #f98506;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
.admin-header-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #0395f9 !important;
}

.v-btn--continue {
  position: absolute;
  right: 9rem;
  top: 64px;
  height: 38px !important;
  width: 375px !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  text-transform: none !important;
  color: #06fccd !important;

  span > span {
    display: block !important;
  }
}
