.animation {
  &-spin {
    animation:spin 2s ease-in-out infinite;
  }
  &-spin-back {
    animation:spin-back 2s ease-in-out infinite;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

@keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
    transform:rotate(-360deg);
  }
}
