@import '@/styles/variables.scss';





















































































































.wrapper {
  position: fixed;
  bottom: 70px;
  left: auto;
  right: 0;
  transform: translateX(0%);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  z-index: 99;
}
.wrapper-hidden {
  transform: translateX(60%);
  bottom: 90px;
}
.avatar-center {
  cursor: pointer;
}

.bubble-card {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  .v-image__image {
    background-color: white;
  }
  .v-avatar {
    cursor: pointer;
    border: 3px solid;
  }
  .v-avatar_outer {
    cursor: pointer;
    border: 1px solid;
  }
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: all;
  //  transition: all ease-in-out .3s;
}
.bubble-hidden {
  padding: 0;
  background-color: transparent !important;
  border-color: transparent !important;
  overflow: hidden;
}
.bubble-content-hidden {
  opacity: 0;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: all;
}
.iframe-container {
  height: 100%;
  iframe {
    min-height: calc(100vh - 340px);
  }
}
.v-overlay--support {
  .v-overlay__scrim {
    background-color: white !important;
  }
}
