@import '@/styles/variables.scss';


























































































































































































@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins.scss';

@include theme(horizontal-nav-menu-group) using ($material) {
  color: map-deep-get($material, 'text', 'primary');

  &.horizontal-nav-menu-group-active {
    background: rgba(map-deep-get($shades, 'black'), map-deep-get($material, 'states', 'selected'));
  }

  &:first-child + .v-menu > .v-menu__content {
    top: -8px !important;
  }
}

.horizontal-nav-menu {
  .horizontal-nav-menu-group-scrollable {
    &.v-menu__content {
      overflow-y: auto !important;
      @include style-scroll-bar();
      .v-list {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
  }
}
