@import '@/styles/variables.scss';


























































@import '~vuetify/src/styles/styles.sass';
.horizontal-nav-menu-header-link {
  // border-radius: 3px;
  text-transform: none;
  color: #2830ce !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  svg {
    fill: currentColor;
  }
}

.header-link-active {
  background-color: transparent;
  font-weight: bold;
  text-decoration: underline;
  &::before {
    background-color: transparent;
  }
}
.horizontal-nav-menu-header-link.header-link-active {
  // box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
  // @include elevationTransition();
}
